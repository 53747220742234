import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { Location, useLocation } from 'react-router-dom';

import { useGetOrderTypesQuery } from '@apis/trackOrders/TrackOrders.api';
import {
    GetAvailableWarehousesResponse,
    GetOrdersDTO,
    GetShippingServices,
    OrderType
} from '@apis/trackOrders/TrackOrdersApi.types';
import { Account } from '@apis/users/UsersApi.types';
import { isMobile, isMobileAndTablet } from '@constants/breakpoints';
import {
    deliveryTypes,
    ORDER_DATE_FILTER_TYPE,
    orderDateFilterTypesData,
    partConditionTypes
} from '@constants/trackOrdersMockedData';
import {
    Pagination,
    SORTING_DIRECTION
} from '@customTypes/general/general.types';
import { TRACKING_SEARCH_PARAMS } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { areUniqueArraysEqual } from '@helpers/array';
import {
    getDateString,
    getLowercaseDateFormat
} from '@helpers/dateAndTimeFormatters';
import { getBasicSort } from '@helpers/getBasePaginationBody';
import {
    DefaultActiveStatuses,
    getFormattedStatuses,
    PROGRESS_STATUSES
} from '@helpers/orderStatus';
import { onAccountChangeWithParams } from '@helpers/paramsHandler';
import {
    useSelectedAccountsAndSubs,
    useSelectedTransportType
} from '@hooks/useAllTreeHelpers';
import { useLastUpdate } from '@hooks/useLastUpdate';
import { useSearchFilter } from '@hooks/useSearchFilter';
import { useUser } from '@hooks/useUser';
import { useUserSettings } from '@hooks/useUserSettings';
import { useWindowDimentions } from '@hooks/useWindowDimentions';
import { RangeNullableDates } from '@molecules/rangePicker/RangePicker';
import {
    checkIfDefaultPeriodSelected,
    getDateFilterData,
    getFilters,
    getWarehousesFilter,
    DateFilterData,
    GetOrderFiltersProps,
    WAREHOUSE_FILTER_PREFIX
} from '@organisms/trackOrders/orderList/ordersListUrlUtils';
import { useOrdersListUrl } from '@organisms/trackOrders/orderList/useOrdersListUrl';

export type SelectedFilterItems = {
    title: string;
    value: string;
}[];

interface OrdersListState extends Location {
    state: { redirectFromWatchList: boolean };
}

const getSelectedWarehouses = (keys: string[] = []) =>
    keys.map((item) => {
        const splitValue = item.split('_');

        return { title: splitValue[splitValue.length - 1], value: item };
    });

interface OrdersListReturnType extends Pagination {
    searchQuery?: string;
    searchInputValue?: string;
    onChangeSearch: (value: string) => void;
    onSubmitSearch: (value: string) => void;
    onSearchInputClean: VoidFunction;
    lastUpdated?: string;
    onRefreshClick: VoidFunction;
    applyOrderTypes: (orderTypes: string[]) => void;
    applyAccountsAndSubAccounts: (accounts: string[]) => void;
    onRemoveOrderType: (orderType: string) => void;
    onRemoveAccountsAndSubs: (id: string) => void;
    selectedOrderTypes?: string[];
    selectedPartConditionTypes?: string[];
    selectedDeliveryTypes?: string[];
    orderTypes?: OrderType[];
    partConditionTypesList?: OrderType[];
    deliveryTypesList?: OrderType[];
    selectedFilters: boolean;
    hasSelectedAccounts: boolean;
    hasSelectedOriginLocations: boolean;
    hasSelectedDestinationLocations: boolean;
    clearAllSelectedFilters: VoidFunction;
    onResetPeriod: VoidFunction;
    dateFilterData: DateFilterData;
    minAvailableDateRange: Date;
    onChangeDates: (dates: RangeNullableDates, dateType: string) => void;
    selectedDates: string[];
    selectedDatesLabel: string;
    accounts: Account[] | undefined;
    accountsAndSubs?: string[];
    transportTypes?: string[];
    selectedTransportTypes?: { title: string; value: string }[];
    originLocations?: string[];
    hasSelectedTransportTypes?: boolean;
    selectedOriginLocations?: SelectedFilterItems;
    destinationLocations?: string[];
    selectedDestinationLocations?: SelectedFilterItems;
    selectedAccountsAndSubs: SelectedFilterItems;
    onStatusSelect: (status: PROGRESS_STATUSES) => void;
    showDangerousGoods?: string;
    onRemoveDangerousGoodsParam: (value: string) => void;
    onRemovePartConditionParam: (value: string) => void;
    onRemoveDeliveryParam: (value: string) => void;
    onRemoveOriginLocationsParam: (value: string) => void;
    onRemoveTransportType: (value: string) => void;
    onRemoveDestinationLocationsParam: (value: string) => void;
    status?: string;
    warehousesData?: GetAvailableWarehousesResponse;
    isDestinationsLoading?: boolean;
    showWatchList?: string;
    onRemoveWatchListParam: VoidFunction;
    allTransportTypes?: GetShippingServices;
}

export const getSortingByDateType = (dateType: string) => {
    const sortObj = {
        field: Object.values<string>(ORDER_DATE_FILTER_TYPE).includes(dateType)
            ? dateType
            : ORDER_DATE_FILTER_TYPE.LATEST_EVENT,
        direction: SORTING_DIRECTION.DESC
    };

    return getBasicSort(sortObj);
};

export const useOrdersListFilters = (
    getData: (config: GetOrdersDTO) => void,
    orderCacheId: string | undefined
): OrdersListReturnType => {
    const { data: orderTypesData } = useGetOrderTypesQuery();
    const { accounts: accountsRawData, fetchAccounts } = useUser();
    const orderTypes = orderTypesData?.data;
    const partConditionTypesList = partConditionTypes;
    const deliveryTypesList = deliveryTypes;
    const location = useLocation() as OrdersListState;
    const isRedirectFromWatchList = location?.state?.redirectFromWatchList;
    const { width } = useWindowDimentions();
    const isMobileVersion = isMobile(width);

    useEffect(() => {
        fetchAccounts?.(true);
    }, []);

    const {
        setParams,
        pagination,
        sorting,
        removeParam,
        orderTypes: selectedOrderTypes,
        partConditionTypes: selectedPartConditionTypes,
        deliveryTypes: selectedDeliveryTypes,
        dateFilterData,
        minAvailableDateRange,
        search,
        filters,
        accountsAndSubs,
        originLocations,
        destinationLocations,
        status,
        warehousesData,
        isDestinationsLoading,
        showDangerousGoods: dangerousGoodsSelectedState,
        showWatchList: watchListSelectedState,
        transportTypes,
        allTransportTypes,
        shippingServices
    } = useOrdersListUrl();

    const formattedStatus = status ? status.join('|') : 'null';

    const ordersConfig: GetOrdersDTO = {
        filters,
        sorts: getBasicSort(sorting),
        page: pagination.page,
        pageSize: pagination.pageSize
    };

    const { searchValue, onChangeSearch, onSearchInputClean, onSubmitSearch } =
        useSearchFilter({
            initialValue: search,
            setParams,
            removeParam,
            getData,
            config: ordersConfig,
            sorting,
            status: status ? status.join('|') : PROGRESS_STATUSES.TOTAL,
            getFilters,
            defaultDateRangeInDays: dateFilterData?.defaultDateRangeDays,
            isMobile: isMobileVersion
        });

    const { setLastUpdated, fromLastRefresh } = useLastUpdate();

    const { dateFormatKey } = useUserSettings();

    const currentDate = DateTime.now();
    const watchlistDateFrom = currentDate.minus({ year: 1 }).toISODate();
    const watchlistDateTo = currentDate.toISODate();
    const watchlistDateType = 'CreateDate';

    const watchlistConfig: GetOrdersDTO = {
        filters: getFilters({
            showWatchList: 'true',
            status: PROGRESS_STATUSES.TOTAL,
            date: getDateFilterData({
                dateFrom: watchlistDateFrom,
                dateTo: watchlistDateTo,
                dateType: watchlistDateType
            }).filterData
        }),
        sorts: '-CreateDate',
        page: 1,
        pageSize: 10
    };

    useEffect(() => {
        const chosenConfig = isRedirectFromWatchList
            ? watchlistConfig
            : ordersConfig;

        if (isRedirectFromWatchList) {
            setParams({
                sorts: '-CreateDate',
                dateFrom: watchlistDateFrom,
                dateTo: watchlistDateTo,
                dateType: watchlistDateType,
                page: 1,
                pageSize: 10,
                showWatchList: 'true',
                status: formattedStatus
            });
        }

        if (warehousesData) {
            getData({ ...chosenConfig });

            setLastUpdated(Date.now());
        }
    }, [
        warehousesData,
        ordersConfig.filters,
        watchlistConfig.filters,
        pagination.page,
        pagination.pageSize,
        sorting.field,
        sorting.direction
    ]);

    useEffect(() => {
        setLastUpdated(Date.now());
    }, [orderCacheId]);

    const onRefreshClick = () => {
        getData({
            ...ordersConfig,
            page: 1
        });

        setParams({ page: 1, status: formattedStatus });
        setLastUpdated(Date.now());
    };

    const getFilterConfig = (config?: Partial<GetOrderFiltersProps>) => {
        return {
            orderTypes: selectedOrderTypes,
            partConditionTypes: selectedPartConditionTypes,
            deliveryTypes: selectedDeliveryTypes,
            date: getDateFilterData({
                dateFrom: dateFilterData.from,
                dateTo: dateFilterData.to,
                dateType: dateFilterData.dateType
            }).filterData,
            showWatchList: watchListSelectedState,
            shippingServices: shippingServices,
            accountsAndSubs,
            originLocations,
            destinationLocations,
            status: status?.join('|'),
            accounts: accountsRawData,
            warehouses: warehousesData?.data,
            ...config
        };
    };

    const applyOrderTypes = (types: string[]) => {
        if (!types.length) {
            removeParam(['orderTypes', 'page']);
        } else {
            setParams({
                orderTypes: types.join(','),
                page: 1,
                status: formattedStatus
            });
        }

        getData({
            ...ordersConfig,
            filters: getFilters(getFilterConfig({ orderTypes: types })),
            page: 1
        });
    };

    const onRemoveOrderType = (type: string) => {
        const withoutRemoved =
            selectedOrderTypes?.filter((orderType) => orderType !== type) || [];
        applyOrderTypes(withoutRemoved);
    };

    const onChangeDates = (
        [from, to]: RangeNullableDates,
        dateType: string
    ) => {
        const dateFrom = getDateString(from);
        const dateTo = getDateString(to);
        const newSorting = getSortingByDateType(dateType);

        setParams({
            sorts: newSorting,
            dateFrom,
            dateTo,
            dateType,
            page: 1,
            status: formattedStatus
        });

        getData({
            ...ordersConfig,
            page: 1,
            sorts: newSorting,
            filters: getFilters(
                getFilterConfig({
                    date: getDateFilterData({ dateFrom, dateTo, dateType })
                        .filterData
                })
            )
        });
    };

    const removeAllFilterParams = () => {
        const paramsToRemove = [
            'dateFrom',
            'dateTo',
            'dateType',
            'orderTypes',
            'partConditionTypes',
            'deliveryTypes',
            'accountsAndSubs',
            'transportTypes',
            'originLocations',
            'destinationLocations',
            'showDangerousGoods',
            'page',
            'showWatchList'
        ];

        if (isMobileAndTablet(width)) {
            removeParam(paramsToRemove, {
                status: DefaultActiveStatuses.join('|')
            });
        } else {
            removeParam(paramsToRemove);
        }
    };

    const onRemoveDangerousGoodsParam = () => {
        removeParam(['showDangerousGoods', 'page']);

        getData({
            ...ordersConfig,
            filters: getFilters(getFilterConfig()),
            page: 1
        });
    };

    const onRemoveWatchListParam = () => {
        removeParam(['showWatchList', 'page']);

        getData({
            ...ordersConfig,
            filters: getFilters(getFilterConfig()),
            page: 1
        });
    };

    const applyBasicFilter = (
        data: string[],
        paramName: TRACKING_SEARCH_PARAMS
    ) => {
        if (!data.length) {
            removeParam([paramName, 'page']);
        } else {
            let formattedData;

            if (paramName === TRACKING_SEARCH_PARAMS.DESTINATION_LOCATIONS) {
                formattedData = getWarehousesFilter(
                    WAREHOUSE_FILTER_PREFIX.DESTINATION,
                    data,
                    warehousesData?.data
                );
            } else if (paramName === TRACKING_SEARCH_PARAMS.ORIGIN_LOCATIONS) {
                formattedData = getWarehousesFilter(
                    WAREHOUSE_FILTER_PREFIX.ORIGIN,
                    data,
                    warehousesData?.data
                );
            } else {
                formattedData = data.join(',');
            }

            setParams({ [paramName]: formattedData, page: 1 });
        }

        const filters = getFilters(
            getFilterConfig({
                [paramName]: data
            })
        );

        getData({ ...ordersConfig, page: 1, filters });
    };

    const onRemoveBasicFilter = (
        itemToDelete: string,
        paramName: TRACKING_SEARCH_PARAMS,
        data?: string[]
    ) => {
        const filteredData = data?.filter((item) => item !== itemToDelete);

        if (filteredData) {
            applyBasicFilter(filteredData, paramName);
        }
    };

    const onRemoveOriginLocationsParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.ORIGIN_LOCATIONS,
            originLocations
        );
    };

    const onRemoveTransportType = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.TRANSPORT_TYPES,
            transportTypes
        );
    };

    const onRemoveDestinationLocationsParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.DESTINATION_LOCATIONS,
            destinationLocations
        );
    };

    const onRemovePartConditionParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.PART_CONDITION,
            selectedPartConditionTypes
        );
    };

    const onRemoveDeliveryParam = (itemToDelete: string) => {
        onRemoveBasicFilter(
            itemToDelete,
            TRACKING_SEARCH_PARAMS.DELIVERY_TYPES,
            selectedDeliveryTypes
        );
    };

    const clearAllSelectedFilters = () => {
        removeAllFilterParams();
        getData({
            ...ordersConfig,
            sorts: getBasicSort(sorting),
            filters: getFilters(
                { status: DefaultActiveStatuses.join('|') },
                false,
                dateFilterData.defaultDateRangeDays
            ),
            page: 1
        });
    };

    const onResetPeriod = () => {
        removeParam(['dateFrom', 'dateTo', 'dateType', 'sorts', 'page']);

        getData({
            ...ordersConfig,
            sorts: '-LatestMilestoneDate',
            filters: getFilters(
                getFilterConfig({
                    date: getDateFilterData({
                        defaultDateRangeDays:
                            dateFilterData.defaultDateRangeDays
                    }).filterData,
                    accounts: accountsRawData,
                    warehouses: warehousesData?.data
                })
            ),
            page: 1
        });
    };

    const selectedDestinationLocations =
        getSelectedWarehouses(destinationLocations);
    const selectedOriginLocations = getSelectedWarehouses(originLocations);

    const selectedDates = [
        `${DateTime.fromSQL(dateFilterData.from).toFormat(
            getLowercaseDateFormat({ dateFormatKey })
        )} - ${DateTime.fromSQL(dateFilterData.to).toFormat(
            getLowercaseDateFormat({ dateFormatKey })
        )}`
    ];

    const selectedDatesLabel =
        orderDateFilterTypesData.find(
            ({ value }) => value === dateFilterData.dateType
        )?.label || '';

    const hasSelectedDatesAndRange = !checkIfDefaultPeriodSelected({
        defaultDateRangeDays: dateFilterData.defaultDateRangeDays,
        from: dateFilterData.from,
        to: dateFilterData.to,
        dateType: dateFilterData.dateType
    });
    const hasSelectedAccounts = !!accountsAndSubs?.length;
    const hasSelectedTransportTypes = !!transportTypes?.length;
    const hasSelectedOriginLocations = !!selectedOriginLocations?.length;
    const hasSelectedDestinationLocations =
        !!selectedDestinationLocations?.length;
    const hasSelectedDeliveryTypes = !!selectedDeliveryTypes?.length;
    const hasSelectedPartConditionTypes = !!selectedPartConditionTypes?.length;

    const hasSelectedStatuses = status
        ? !areUniqueArraysEqual(status, DefaultActiveStatuses) &&
          isMobileVersion
        : false;

    const applyAccountsAndSubAccounts = (data: string[]) => {
        const filters = getFilters(
            getFilterConfig({
                accountsAndSubs: data
            })
        );

        onAccountChangeWithParams({
            data,
            removeParam,
            setParams,
            config: ordersConfig,
            getData,
            filter: filters,
            allAccounts: accountsRawData,
            status: formattedStatus
        });
    };

    const { selectedAccountsAndSubs, onRemoveAccountsAndSubs } =
        useSelectedAccountsAndSubs({
            data: accountsAndSubs,
            rawData: accountsRawData,
            onApply: applyAccountsAndSubAccounts
        });

    const { selectedTransportTypes } = useSelectedTransportType(
        transportTypes,
        allTransportTypes?.data
    );

    const selectedFilters =
        Boolean(selectedOrderTypes?.length) ||
        hasSelectedDatesAndRange ||
        hasSelectedAccounts ||
        hasSelectedOriginLocations ||
        hasSelectedDestinationLocations ||
        hasSelectedTransportTypes ||
        (dangerousGoodsSelectedState &&
            dangerousGoodsSelectedState !== 'false') ||
        (watchListSelectedState && watchListSelectedState !== 'false') ||
        hasSelectedDeliveryTypes ||
        hasSelectedPartConditionTypes ||
        hasSelectedStatuses;

    const onStatusSelect = (selectedStatus: PROGRESS_STATUSES) => {
        const { newStatus } = getFormattedStatuses(status, selectedStatus);

        setParams({ status: newStatus || 'null', page: 1 });

        getData({
            ...ordersConfig,
            filters: getFilters(
                getFilterConfig({
                    status: newStatus,
                    date: getDateFilterData({
                        dateFrom: dateFilterData.from,
                        dateTo: dateFilterData.to,
                        dateType: dateFilterData.dateType
                    }).filterData
                })
            ),
            page: 1
        });
    };

    return {
        searchQuery: search,
        searchInputValue: searchValue,
        onChangeSearch,
        onSubmitSearch,
        onSearchInputClean,
        lastUpdated: fromLastRefresh,
        onRefreshClick,
        applyOrderTypes,
        onRemoveOrderType,
        selectedOrderTypes,
        selectedPartConditionTypes,
        selectedDeliveryTypes,
        showDangerousGoods: dangerousGoodsSelectedState,
        showWatchList: watchListSelectedState,
        onRemoveDangerousGoodsParam,
        onRemoveWatchListParam,
        onRemovePartConditionParam,
        onRemoveDeliveryParam,
        onRemoveOriginLocationsParam,
        onRemoveDestinationLocationsParam,
        orderTypes,
        partConditionTypesList,
        deliveryTypesList,
        selectedFilters,
        clearAllSelectedFilters,
        dateFilterData,
        minAvailableDateRange,
        onChangeDates,
        onResetPeriod,
        selectedDates,
        selectedDatesLabel,
        accounts: accountsRawData,
        accountsAndSubs,
        selectedTransportTypes,
        transportTypes,
        hasSelectedTransportTypes,
        originLocations,
        selectedOriginLocations,
        destinationLocations,
        selectedDestinationLocations,
        hasSelectedAccounts,
        hasSelectedOriginLocations,
        hasSelectedDestinationLocations,
        selectedAccountsAndSubs,
        onRemoveAccountsAndSubs,
        applyAccountsAndSubAccounts,
        onStatusSelect,
        status: status?.join('|'),
        warehousesData: warehousesData,
        isDestinationsLoading,
        onRemoveTransportType,
        allTransportTypes,
        ...pagination
    };
};
